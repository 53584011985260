import { HttpResponse } from "@angular/common/http";
import { TranslateService } from "@ngx-translate/core";
import { BehaviorSubject, firstValueFrom, Subscription } from "rxjs";
import { ButtonAction } from "src/app/components/default/buttons/buttons.component";
import { FormCategory } from "src/app/components/default/form/form-category";
import { FormConditional } from "src/app/components/default/form/form-conditional";
import { FormField, FormFieldTile } from "src/app/components/default/form/form-field";
import { FORM_TEMPLATE } from "src/app/components/default/form/form-template";
import { FormAction, FormComponent, StateChange } from "src/app/components/default/form/form.component";
import { Mode, State } from "src/app/components/default/form/form.definition";
import {
  FormCredits,
  FormFieldResponse,
  FormOptions,
  FormResponseButton,
  GridValue,
  GridValueResponse,
  ProgressResponse,
  SaoResponse,
  ServerAttachment,
} from "src/app/components/default/form/form.interface";
import { DialogService, DialogTemplate } from "src/app/components/global/dialog/dialog.service";
import { AttachmentsComponent, AttachmentsDialogData } from "src/app/components/global/dialog/impl/attachments/attachments.component";
import { Group as SAOGroup } from "src/app/components/global/prefixes/impl/prefix-sao/prefix-sao.component";
import { Polygon } from "src/app/components/global/prefixes/templates/template-map/template-map.component";
import { ProgressValue } from "src/app/components/global/prefixes/templates/template-progress/template-progress.component";
import { ErrorMessageComponent } from "src/app/components/global/snackbar/impl/error-message/error-message.component";
import { Attachment } from "src/classes/Attachment";
import { FileManager } from "src/classes/FilesManager";
import { ROUTES_CONFIG } from "src/config/routes.config";
import { AttachmentStatus } from "src/enums/attachment-status";
import { ActionResponse, PostResponse, PostResult } from "src/interfaces/post-request/post-request";
import { ApplicationService } from "src/services/application.service";
import { HttpObserve, HttpService } from "src/services/http.service";
import { SessionService } from "src/services/session.service";
import { ContentPart } from "../content-part";

export interface FormAttachmentOptions {
  dialog: DialogService;
  form: FormComponent;
}

interface FormFieldCollection {
  field: FormField;
  data: FormFieldResponse;
}

/**
 * Conversion of FFWD data to useable data
 */
export class ContentForm extends ContentPart {
  private application: ApplicationService;
  public http: HttpService;
  public session: SessionService;
  private translate: TranslateService;
  private dialog: DialogService;

  public fields: Map<number, FormCategory>;
  public fieldCollection: FormField[];

  public attachments: Map<number, Attachment>;
  public buttons: FormResponseButton[];

  public tabindex: string | null;
  public contentid: string | null;
  public contentpartid: string | null;
  public options: FormOptions;

  public buttonenabled: boolean;

  public conditions: Map<string, FormField>;

  public credits: FormCredits;

  public subscriptions: Subscription;

  public mode: Mode;

  public constructor(
    id: string,
    http: HttpService,
    application: ApplicationService,
    session: SessionService,
    translate: TranslateService,
    dialog: DialogService,
  ) {
    super(id, "Form");
    this.http = http;
    this.application = application;
    this.session = session;
    this.translate = translate;
    this.dialog = dialog;

    this.fields = new Map<number, FormCategory>();
    this.fieldCollection = [];
    this.attachments = new Map<number, Attachment>();
    this.tabindex = null;
    this.contentid = null;
    this.contentpartid = null;
    this.buttons = [];
    this.options = {
      attachments: true,
      foot: true,
      head: true,
      multi: true,
    };
    this.mode = Mode.EDIT;

    this.buttonenabled = false;

    this.conditions = new Map();

    this.credits = {
      author: "",
      createdAt: 0,
      editedAt: 0,
      editor: "",
    };

    this.subscriptions = new Subscription();
  }

  public async onEvent(event: FormAction): Promise<void> {
    const type = event.type;
    const form = event.form;
    const params = event.params;
    const dialogRef = event.dialogRef;
    const fields = <FormField[]>params.shift();

    form.state.next(State.BUSY);

    this.application.loading.next(true);
    switch (type) {
      case ButtonAction.SAVE:
        await this.onSave(fields, <string | null>params.shift(), dialogRef, <boolean>params.shift());
        break;

      case ButtonAction.SNAPSHOT:
        await this.onSnapshot(<string>params.shift(), <string | null>params.shift());
        break;

      case ButtonAction.REDIRECT:
        await this.onRedirect(<string>params.shift());
        break;

      case ButtonAction.ACTION:
        await this.onAction(fields, <string>params.shift());
        break;

      case ButtonAction.DOWNLOAD:
        await this.onDownload(fields, <string>params.shift(), <string | null>params.shift());
        break;

      case ButtonAction.RELOAD:
        this.onReload();
        break;

      default:
        console.warn("[FORM] Unknown form action => ", {
          type,
          form,
          fields,
          params,
        });
        break;
    }
    this.application.loading.next(false);

    form.state.next(State.VALID);
  }

  /**
   * State event listener
   * @param state
   */
  public onStateChange(event: StateChange): void {
    console.warn(`[FORM] State changed => `, event);
  }

  public openAttachments(options: FormAttachmentOptions): void {
    const dialog = options.dialog;
    const form = options.form;

    dialog.open<AttachmentsDialogData>(
      AttachmentsComponent,
      {
        title: {
          label: "DIALOG.ATTACHMENT.TITLE",
          params: { title: form.title },
        },
        attachments: Array.from(form.attachments.values()),
        contentId: this.contentid,
        contentPartId: this.contentpartid,
      },
      DialogTemplate.MODAL,
    );
  }

  public parseAttachments(attachments: ServerAttachment[]): Map<number, Attachment> {
    const map = new Map<number, Attachment>();
    const reshapedAttachment = <Attachment[]>attachments.map((attachment) => {
      return new Attachment(attachment.deletetarget, attachment.pa2Bestandsnaam, attachment.link, AttachmentStatus.LOADED, attachment.description);
    });

    reshapedAttachment.forEach((attachment, i) => {
      map.set(i, attachment);
    });

    return map;
  }

  /**
   * Get values as values that api expects
   * @param field
   */
  public async getFieldValue(field: FormField): Promise<string | Blob | string[] | Blob[]> {
    const value = await firstValueFrom(field.value);

    switch (field.template) {
      case "jan":
      case "jn3":
        return (<boolean>Object.values(<Record<string, unknown>>value)[0]).toString();

      case "dis":
      case "di3":
        return value != null ? (<Date>value).toISOString() : "";

      case "gri":
      case "gr2":
      case "gr3":
        return JSON.stringify(
          (<GridValue[]>value).map((value) => ({
            id: value.id,
            ck: value.checked,
            ot: value.text || undefined,
            df: value.dateFrom || "",
            dt: value.dateTo || "",
          })),
        );

      // case "geo":
      // case "gpl":
      // case "gp2":
      //   return JSON.stringify(<GeoValue>value);

      case "ti3":
      case "ti4":
        return value ? `${value}:00.000` : "";

      case "da2":
      case "dat":
        return `${value ? this.formatDate(<string>value) : value}`;

      case "sao": {
        return this.getSAOStringValue(<SAOGroup[]>value);
      }

      case "fil": {
        if (value instanceof FileList) {
          console.log("FileList", value);
          const files: string[] = [];
          for (const file of Array.from(value)) {
            const res = await this.toBase64(file);
            files.push(res);
          }
          return files;
        } else if (value instanceof File) {
          console.log("File", value);
          return this.toBase64(value);
        } else {
          console.log("Unknown", value);
          return "";
        }
      }

      default:
        return <string>value || "";
    }
  }

  /**
   * Parse fields into categories and fields
   * @param fields
   */
  public parseFields(fields: FormFieldResponse[]): Map<number, FormCategory> {
    let category = new FormCategory("", true);
    const fieldCollection: FormFieldCollection[] = [];
    const rows = new Map<number, FormCategory>();

    let categoryFields: Map<number, FormField> = new Map();
    for (const field of fields.values()) {
      if (field.name == "hidRecordGemaakt") {
        this.credits.createdAt = <number>field.value;
      } else if (field.name == "belAdministratieAutorisatieGemaakt") {
        this.credits.author = <string>field.value;
      } else if (field.name == "hidRecordGewijzigd") {
        this.credits.editedAt = <number>field.value;
      } else if (field.name == "belAdministratieAutorisatieGewijzigd") {
        this.credits.editor = <string>field.value;
      } else if (["kok", "kop", "koh"].includes(field.pfx)) {
        category.threatListener();
        rows.set(rows.size, category);
        category.setFields(categoryFields);
        category = new FormCategory(field.name, true, field.pfx === "kok", this.sanitizeName(field));
        categoryFields = new Map();
        this.setConditions(category);
        // TODO: Implement conditions
      } else {
        const label = this.sanitizeName(field);
        const value = this.sanitizeValue(field);
        const formfield = new FormField(field.orgname || field.name, value, FORM_TEMPLATE[field.pfx] || field.pfx || FORM_TEMPLATE.DEFAULT, label);
        try {
          this.setConditions(formfield);
        } catch {
          console.error("Conditions not set");
        }

        const entry = {
          field: formfield,
          data: field,
        };
        this.setFieldOptions(entry);
        fieldCollection.push(entry);

        categoryFields.set(categoryFields.size, formfield);
      }
    }
    category.setFields(categoryFields);

    this.fieldCollection = fieldCollection.map((entry) => entry.field);

    rows.set(rows.size, category);
    category.threatListener();
    return rows;
  }

  /**
   * Deconstruct a form and all of it's FormFields
   */
  public override deconstruct(): void {
    this.subscriptions.unsubscribe();
    for (const category of this.fields.values()) {
      category.subscriptions.unsubscribe();
      for (const field of category.fields.values()) {
        field.subscriptions.unsubscribe();
      }
    }
  }

  /**
   * Set CWA and CWB values to conditional fields
   * @param field
   */
  private setConditions(field: FormConditional): void {
    if (field instanceof FormField && field.name.includes("#CWB")) {
      const id = field.name.split("#CWB")[1].split("#CWA")[0];
      this.conditions.set(id, field);
    }

    if (field.name.includes("#CWA")) {
      const [id, trigger] = field.name.split("#CWA")[1].split("#CWB")[0].split(":");
      const parent = this.conditions.get(id) || null;
      const condition = new BehaviorSubject(true);
      const inverted = trigger.startsWith("!");

      if (parent) {
        this.subscriptions.add(
          parent.value.subscribe((value) => {
            let visible = true;

            if (trigger == "on") {
              visible = value == true || Object.values(<Record<string, boolean>>value)[0] == true;
            } else if (trigger == "off") {
              visible = value == false || Object.values(<Record<string, boolean>>value)[0] == false;
            } else if (trigger.includes("~")) {
              visible = trigger.split("~").includes(<never>value);
            } else if (trigger == "null") {
              visible = value == null || value == "" || value == "null"; //TODO - option value = "-" set to null
            } else if (trigger == "notnull") {
              visible = value != null && value != "" && value != "null"; //TODO - replace on backend by "!null"
            } else {
              visible = value == trigger;
            }

            if (!parent.condition.value) {
              visible = false;
            }

            condition.next(inverted ? !visible : visible);
          }),
        );

        field.setCondition(condition);
      } else {
        // throw new Error("Failed to parse conditional field! (unknown dependency)");
        console.error("Failed to parse conditional field! (unknown dependency)", field.name);
      }
    }
  }

  /**
   * Parse name to correct label
   * @param name
   * @returns
   */
  private sanitizeName(field: FormFieldResponse): string {
    switch (field.pfx) {
      default:
        return field.name.substring(3).split("|")[0].split(":")[0];
    }
  }

  /**
   * Converts the value from the representation that the server uses to an internal representation that's used inside the prefixes
   */
  private sanitizeValue(field: FormFieldResponse): unknown {
    switch (field.pfx) {
      case "gri":
      case "gr2":
      case "gr3":
        return this.sanitizeGrid(field.gridvalues || []);

      case "pge":
      case "prg":
        return (<ProgressValue>(<unknown>this.parseProgress(<ProgressResponse>field.value))).current;

      case "dis":
      case "di3":
        return field.value != null ? new Date(<string>field.value) : null;

      case "jan":
      case "jn3":
        return {
          [this.sanitizeName(field)]: this.sanitizeBoolean(field.value),
        };

      case "xja":
        return this.sanitizeBoolean(field.value);

      case "kop":
        return this.sanitizeName(field);

      case "img":
        return (<string>field.value).split("|")[0];

      case "sao":
        return field.value;

      case "da2":
      case "dat":
        return (<string | null>field.value)?.split(" ").shift() || null;

      case "tid":
      case "ti2":
        return <string | null>field.value ? (new Date(<string>field.value)?.toTimeString()?.substring(0, 5) ?? null) : null;

      case "ti3":
      case "ti4":
        return (<string | null>field.value)?.substring(0, 16) ?? null;

      case "gep":
        return null;

      case "geo":
      case "gpl":
      case "gp2":
        return typeof field.value === "string" ? JSON.parse(field.value) : null;
      default:
        return field.value == "" ? null : field.value;
    }
  }

  /**
   * Create the extras object depending on prefix
   * @param field
   * @returns
   */
  private getExtras(field: FormFieldResponse): Record<string, unknown> {
    switch (field.pfx) {
      case "img":
        return this.parseImage(<string>field.value);

      case "lnk":
      case "ln2":
      case "ln3":
      case "ln4":
        return this.parseLink(field.links);

      case "tbl":
        return this.parseTable(field.tabledata);

      case "idf":
      case "idh":
      case "idi":
      case "ido":
      case "idr":
      case "ids":
      case "idu":
      case "idj":
      case "idt":
      case "idl":
        return this.parseSelect(field.listvalues);
        break;

      case "xja":
        return this.parseLabel(this.sanitizeName(field));

      case "idg":
        return {
          ...this.parseSelect(field.listvalues),
          ...this.parseSelectGroups(field.listvaluesgroups),
        };

      case "gri":
      case "gr2":
      case "gr3":
        return {
          text: field.gridhastext == "true",
          date: field.gridhastimespan == "true",
        };

      case "jan":
      case "jn3":
        return this.parseCheckbox([
          {
            value: <string>field.value,
            description: this.sanitizeName(field),
          },
        ]);

      case "sao": {
        const value = <SaoResponse[]>JSON.parse(<string>field.value);
        return {
          hours: this.fieldCollection.filter((field) => field.name == "hi3Werknemerurenperweek")[0],
          output: this.fieldCollection.filter((field) => field.name == "pr2Percentage arbeidsongeschikt")[0],
          groups: value.map((group) => ({
            labelSuffix: group.n,
            value: group.v.map((values) => ({
              label: values.n,
              value: values.uu4,
            })),
          })),
        };
      }

      case "gep": {
        let polygon: Polygon | null = null;
        try {
          const value = JSON.parse(<string>field.value);
          if (Array.isArray(value) && value.length) {
            polygon = {
              location: value.map(({ lat, lon }: { lat: number; lon: number }) => {
                return { lat, lng: lon };
              }),
            };
          } else {
            throw new Error();
          }
        } catch (error) {
          polygon = null;
        }
        return {
          polygon,
        };
      }

      case "jsn": {
        return {
          schema: field.jsnExtraPropertiesSchema,
        };
      }

      default:
        return {};
    }
  }

  /**
   * Wether to show the field-name or not
   * @param field
   * @returns
   */
  private showLabel(field: FormFieldResponse): number {
    switch (field.pfx) {
      case "koh":
      case "kop":
      case "bla":
      case "jsn":
        return 2;

      case "jan":
      case "jn3":
      case "xja":
      case "toe":
      case "to3":
        return 1;

      default:
        return 0;
    }
  }

  /**
   * Set all field options
   * @param collection
   */
  private setFieldOptions(entry: FormFieldCollection): void {
    const field = entry.field;
    const data = entry.data;
    try {
      field.extras = this.getExtras(data);
      field.labelVisibility = this.showLabel(data);
      field.tooltip = data.tooltips.map((i) => i.value).join("\n");
      field.hidden = this.sanitizeBoolean(entry.data.hidden);
      field.tile = this.getTile(data);
    } catch (error) {
      console.error(`Could not create field options of field => ${field.name}, ${error}`);
    }
  }

  private getTile(data: FormFieldResponse): FormFieldTile | null {
    if (!data["tile-asset"] && !data["tile-maticon"]) {
      return null;
    }

    return {
      icon: data["tile-maticon"] || null,
      image: data["tile-asset"] || null,
    };
  }

  private getSAOStringValue(value: SAOGroup[]): string {
    let lwValues: Record<string, string> = {};
    for (const group of value) {
      lwValues = {
        ...lwValues,
        [group.labelSuffix]: group.value.filter((groupValue) => groupValue.label === "LW")[0].value,
      };
    }

    const mappedValue = value.map((group) => {
      return {
        n: group.labelSuffix,
        v: group.value
          .filter((groupValue) => groupValue.label !== "LW")
          .map((groupValue) => {
            return {
              n: groupValue.label,
              uu4: groupValue.value,
              pr2: lwValues[group.labelSuffix],
            };
          }),
      };
    });
    return JSON.stringify(mappedValue);
  }

  private sanitizeGrid(gridvalues: GridValueResponse[]): GridValue[] {
    return gridvalues.map((gridvalue) => ({
      id: gridvalue.value,
      checked: this.sanitizeBoolean(gridvalue.checked),
      checkedInOtherGrid: this.sanitizeBoolean(gridvalue.checkedinothergrid),
      label: gridvalue.description,
      dateFrom: gridvalue.datVanaf?.value.split(" ").shift() || null,
      dateTo: gridvalue.datTotenmet?.value.split(" ").shift() || null,
      text: gridvalue.bs2Tekst || null,
      hasText: this.sanitizeBoolean(gridvalue.hastext),
    }));
  }

  /**
   * Event listener on form action save
   */
  private async onSave(fields: FormField[], filename: string | null, dialogRef?: string, download = false): Promise<void> {
    try {
      if (filename || download) {
        const response = await this.save<HttpResponse<Blob>>(fields, "blob");
        if (response.body) {
          FileManager.download({
            blob: response.body,
            label: filename || this.getFilenameFromHeader(response.headers) || "null",
          });
        } else {
          throw new Error("Received an empty response");
        }
      } else {
        const response = await this.save<PostResponse>(fields);
        const parsed = <PostResult>JSON.parse(response.postDataAsJSON);

        if (parsed.result === "fail" && !parsed.messages?.length) {
          this.application.snackbar.open<string>(ErrorMessageComponent, "ERRORS.RESPONSE");
          this.application.router.navigate(["/app/home/"]);
        } else {
          this.application.onMessage(parsed.messages ?? []);
        }

        if (dialogRef) {
          this.dialog.close(dialogRef);
        }
      }
    } catch (e) {
      console.error("Unable to save", e);
    }
  }

  /**
   * Event listener on form action snapshot
   */
  private async onSnapshot(actionid: string, filename: string | null): Promise<void> {
    try {
      if (filename) {
        FileManager.download({
          blob: await this.action<Blob>(actionid, "", "blob"),
          label: filename,
        });
      } else {
        const response = await this.actionObserve<Blob>(actionid, "", "blob");
        const disposition = response.headers.get("Content-Disposition");

        if (disposition) {
          FileManager.download({
            blob: response.body,
            label: disposition.split(`filename="`)[1].split(`";`).shift() || "null", // TODO: update to this.getFilenameFromHeader
          });
        } else {
          throw new Error("Unable to find header::Content-Disposition");
        }
      }
    } catch (e) {
      console.error("Unable to snapshot", e);
    }
  }

  /**
   * Event listener on form action redirect
   */
  private async onRedirect(contentid: string): Promise<boolean> {
    return this.application.router.navigate(["/app/content", contentid]);
  }

  /**
   * Event listener on form action action
   */
  private async onAction(fields: FormField[], actionid: string): Promise<void> {
    try {
      const formdata = await this.getFormData(fields);
      let parsed = { messages: [] };
      if (actionid.startsWith("/")) {
        //dynamic action
        const head = await fetch(`${ROUTES_CONFIG.dynactionUrl}${actionid}`, {
          method: "post",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${this.session.id}`,
          },
          body: JSON.stringify(Object.fromEntries(formdata)),
        });
        if (head.headers.has("Content-Disposition")) {
          //download
          return void FileManager.downloadDyn(head);
        }
        const response = await head.json();
        parsed = response;
      } else {
        const response = await this.action<ActionResponse>(actionid, JSON.stringify(Object.fromEntries(formdata)));
        parsed = JSON.parse(response.postActionAsJSON);
      }

      // const parsed = <ActionResult>JSON.parse(response.postActionAsJSON);
      this.application.onMessage(parsed.messages);
    } catch (e) {
      console.error("Unable to action", e);
    }
  }

  /**
   * Event listener on form action download
   */
  private async onDownload(fields: FormField[], actionid: string, filename: string | null): Promise<void> {
    try {
      const formdata = await this.getFormData(fields);

      if (filename) {
        FileManager.download({
          blob: await this.action<Blob>(actionid, JSON.stringify(Object.fromEntries(formdata)), "blob"),
          label: filename,
        });
      } else {
        const response = await this.actionObserve<Blob>(actionid, JSON.stringify(Object.fromEntries(formdata)), "blob");
        const disposition = response.headers.get("Content-Disposition");

        if (disposition) {
          FileManager.download({
            blob: response.body,
            label: disposition.split(`filename="`)[1].split(`";`).shift() || "null", // TODO: update to this.getFilenameFromHeader
          });
        } else {
          throw new Error("Unable to find header::Content-Disposition");
        }
      }
    } catch (e) {
      console.error("Unable to download", e);
    }
  }
  /**
   * Event listener on form action reload
   */
  private onReload(): void {
    this.application.refresh();
  }

  /**
   * Save and send the form
   */
  private async save<T>(fields: FormField[], responseType = "json"): Promise<T> {
    const formdata = await this.getFormData(fields);
    return this.http.send<T>(ROUTES_CONFIG.contentUrl, formdata, {
      observe: responseType == "blob" ? <"body">"response" : "body",
      responseType: <"json">responseType,
    });
  }

  /**
   * Do api action
   */
  private async action<T>(actionid: string, actiondata = "", responseType = "json"): Promise<T> {
    return this.http.send<T>(
      ROUTES_CONFIG.actionurl,
      {
        FFWDActionID: actionid,
        actionData: actiondata,
      },
      {
        responseType: <"json">responseType,
      },
    );
  }

  /**
   * Do api dyn action
   */
  private async dynAction<T>(actionid: string, actiondata = "", responseType = "json"): Promise<T> {
    return this.http.send<T>(`${ROUTES_CONFIG.dynactionUrl}${actionid}`, actiondata, {
      responseType: <"json">responseType,
    });
  }

  /**
   * Do api action and observe response
   */
  private async actionObserve<T>(actionid: string, actiondata = "", responseType = "json"): Promise<HttpObserve<T>> {
    return this.http.send(
      ROUTES_CONFIG.actionurl,
      {
        FFWDActionID: actionid,
        actionData: actiondata,
      },
      {
        responseType: <"json">responseType,
        observe: <"body">"response",
      },
    );
  }

  /**
   * Get formdata of fields
   * @param fields
   * @returns
   */
  private async getFormData(fields: FormField[]): Promise<FormData> {
    const formdata = new FormData();

    if (!this.contentid) throw new Error("Missing contentid in content-form");
    if (!this.contentpartid) {
      throw new Error("Missing contentpartid in content-form");
    }

    formdata.append("tabindex", this.tabindex || "");
    formdata.append("contentid", this.contentid);
    formdata.append("contentpartid", this.contentpartid);

    for (const field of fields) {
      const value = await this.getFieldValue(field);
      console.log(value);
      if (Array.isArray(value)) {
        for (const _value of value) {
          formdata.append(`${field.name}[]`, _value);
        }
      } else {
        formdata.append(field.name, value);
      }
    }

    return formdata;
  }

  private formatDate(value: string): string {
    const split = value.split("-");
    if (split[0] && split[0].length === 4) {
      split.reverse();
    }
    return split
      .map((value, index) => {
        if (index === split.length - 1) return value;
        return value.padStart(2, "0");
      })
      .join("-");
  }

  private toBase64(file: File): Promise<string> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (): void => resolve(reader.result as string);
      reader.onerror = (error): void => reject(error);
    });
  }
}
